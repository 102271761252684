import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaLaptop, FaSchool, FaCertificate } from "react-icons/fa"
import "./index.css"

import Sidebar from "../components/sidebar/Sidebar"
import TechTag from "../components/tags/TechTag"

const AboutPage = (props) => {
    const labels = props.data.site.siteMetadata.labels
    const aboutTags = ["react", "nodejs", "html", "css", "javascript", "vue", "aws", "mongodb", "git", "typescript", "npm", "shopify", "terraform", "jira", "vscode", "elastic"]
    const tags = {}
    labels.forEach(label => {
        aboutTags.forEach(tag => {
            if (tag === label.tag) {
                tags[tag] = label.name
            }
        })
    })

    return (
        <Layout>
            <SEO title="About" />
            <div className="post-page-main">
                <div className="sidebar px-4 py-2">
                    <Sidebar />
                </div>

                <div className="post-main">
                    <SEO title="About" />
                    <div className="mt-3">
                        {/* <h2 className="heading">About</h2>
                        <p><i>Summary</i></p>
                        <br /> */}
                        <h2 className="heading">Work Experience</h2>
                        <div>
                            <span className="d-inline-block" title="okendo">
                                <FaLaptop size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-top">11/2019 - current<br />Okendo - Developer</h4>
                        </div>
                        <div>
                            <p className="d-inline-block ml-3 w-75 align-top">- Back-end and DevOps</p>
                            <div className="ml-3">
                                <TechTag tag="nodejs" tech="Node.js" name={tags["nodejs"]} size={20} color="lightgreen" />
                                <TechTag tag="javascript" tech="JavaScript" name={tags["javascript"]} size={20} color="yellow" />
                                <TechTag tag="aws" tech="AWS" name={tags["aws"]} size={20} color="white" />
                                <TechTag tag="git" tech="Git" name={tags["git"]} size={20} color="white" />
                                <TechTag tag="typescript" tech="TypeScript" name={tags["typescript"]} size={20} color="#007ACC" />
                                <TechTag tag="npm" tech="NPM" name={tags["npm"]} size={20} color="#CB3837" />
                                <TechTag tag="shopify" tech="Shopify" name={tags["shopify"]} size={20} color="#7AB55C" />
                                <TechTag tag="terraform" tech="Terraform" name={tags["terraform"]} size={20} color="#623CE4" />
                                <TechTag tag="jira" tech="Jira" name={tags["jira"]} size={20} color="#172B4D" />
                                <TechTag tag="vscode" tech="VS Code" name={tags["vscode"]} size={20} color="deepskyblue" />
                                <TechTag tag="elastic" tech="ElasticSearch" name={tags["elastic"]} size={20} color="#005571" />
                            </div>
                        </div>
                        <br />
                        <div className="mt-4">
                            <span className="d-inline-block" title="grow">
                                <FaLaptop size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-top">02/2018 - 10/2019<br />GROW Super - Full Stack Developer</h4>
                        </div>
                        <div>
                            <p className="d-inline-block ml-3 w-75 align-top">- Front-end development: HTML, JavaScript, CSS, JS frameworks - React, Vue</p>
                            <p className="d-inline-block ml-3 w-75 align-top">- Back-end development: API design and development, CRUD, RESTful services, JavaScript/Node.js, MongoDB, AWS</p>
                            <p className="d-inline-block ml-3 w-75 align-top">- Testing: Chakram, Mocha, Jest, Cypress'PI testing: Chakram/Mocha    </p>
                            <div className="ml-3">
                                <TechTag tag="react" tech="React" name={tags["react"]} size={20} color="deepskyblue" />
                                <TechTag tag="nodejs" tech="Node.js" name={tags["nodejs"]} size={20} color="lightgreen" />
                                <TechTag tag="html" tech="HTML" name={tags["html"]} size={20} color="darkorange" />
                                <TechTag tag="css" tech="CSS" name={tags["css"]} size={20} color="teal" />
                                <TechTag tag="javascript" tech="JavaScript" name={tags["javascript"]} size={20} color="yellow" />
                                <TechTag tag="vue" tech="Vue.js" name={tags["vue"]} size={20} color="#4FC08D" />
                                <TechTag tag="aws" tech="AWS" name={tags["aws"]} size={20} color="white" />
                                <TechTag tag="jira" tech="Jira" name={tags["jira"]} size={20} color="#172B4D" />
                                <TechTag tag="mongodb" tech="MongoDB" name={tags["mongodb"]} size={20} color="green" />
                                <TechTag tag="git" tech="Git" name={tags["git"]} size={20} color="white" />
                                <TechTag tag="vscode" tech="VS Code" name={tags["vscode"]} size={20} color="deepskyblue" />
                            </div>
                        </div>
                        <br />
                        <br />
                        <h2 className="heading">Education</h2>
                        <div>
                            <span className="text-success d-inline-block" title="acu">
                                <FaSchool size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-top">02/2016 - 12/2017<br />Australian Catholic University</h4>
                            <p className="d-inline-block ml-3 w-75 align-top">Master of Information Technology</p>
                        </div>
                        <div>
                            <span className="text-success d-inline-block" title="fmuk">
                                <FaSchool size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-top">09/2012 - 06/2015<br />Faculty of Management, Comenius University</h4>
                            <p className="d-inline-block ml-3 w-75 align-top">Bachelor of Managerial Informatics</p>
                        </div>
                        <br />
                        <br />
                        <h2 className="heading">Certifications</h2>
                        <div>
                            <span className="text-success d-inline-block" title="acu">
                                <FaCertificate size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-center">
                                <a href="https://www.udemy.com/certificate/UC-SPK7USIW/" target="_blank" rel="noopener noreferrer">Udemy - Beginning C++ Programming - From Beginner to Beyond</a>
                            </h4>
                        </div>
                        <div>
                            <span className="text-success d-inline-block" title="acu">
                                <FaCertificate size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-center">
                                <a href="https://www.udemy.com/certificate/UC-NDK19BTC/" target="_blank" rel="noopener noreferrer">Udemy - Angular Essentials (Angular 2+ with TypeScript)</a>
                            </h4>
                        </div>
                        <div>
                            <span className="text-success d-inline-block" title="acu">
                                <FaCertificate size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-center">
                                <a href="https://www.udemy.com/certificate/UC-L5W996RT/" target="_blank" rel="noopener noreferrer">Udemy - Understanding TypeScript - 2020 Edition</a>
                            </h4>
                        </div>
                        <div>
                            <span className="text-success d-inline-block" title="acu">
                                <FaCertificate size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-center">
                                <a href="https://www.udemy.com/certificate/UC-FQUF8JRE/" target="_blank" rel="noopener noreferrer">Udemy - Asynchronous JavaScript</a>
                            </h4>
                        </div>
                        <div>
                            <span className="text-success d-inline-block" title="acu">
                                <FaCertificate size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-center">
                                <a href="https://www.udemy.com/certificate/UC-X9KR284K/" target="_blank" rel="noopener noreferrer">Udemy - Webpack 4 in 2020: The Complete Tutorial For Beginners</a>
                            </h4>
                        </div>
                        <div>
                            <span className="text-success d-inline-block" title="acu">
                                <FaCertificate size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-center">
                                <a href="https://verify.acloud.guru/092D5F593D51" target="_blank" rel="noopener noreferrer">A Cloud Guru - Introduction to AWS CloudFormation</a>
                            </h4>
                        </div>
                        <div>
                            <span className="text-success d-inline-block" title="acu">
                                <FaCertificate size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-center">AWS Training & Certification</h4>
                            <p className="d-inline-block ml-3 w-75 align-center">
                                <a href="https://www.certmetrics.com/amazon/public/badge.aspx?i=7&t=c&d=2019-06-22&ci=AWS00768598" target="_blank" rel="noopener noreferrer">AWS Certified Security - Specialty</a><br />
                                <a href="https://www.certmetrics.com/amazon/public/badge.aspx?i=3&t=c&d=2019-06-01&ci=AWS00768598" target="_blank" rel="noopener noreferrer">AWS Certified SysOps Administrator - Associate</a><br />
                                <a href="https://www.certmetrics.com/amazon/public/badge.aspx?i=1&t=c&d=2019-05-18&ci=AWS00768598" target="_blank" rel="noopener noreferrer">AWS Certified Solutions Architect - Associate</a><br />
                                <a href="https://www.certmetrics.com/amazon/public/badge.aspx?i=2&t=c&d=2019-03-13&ci=AWS00768598" target="_blank" rel="noopener noreferrer">AWS Certified Developer - Associate</a><br />
                                <a href="https://www.certmetrics.com/amazon/public/badge.aspx?i=9&t=c&d=2018-09-12&ci=AWS00768598" target="_blank" rel="noopener noreferrer">AWS Certified Cloud Practitioner</a>
                            </p>
                        </div>
                        <div>
                            <span className="text-success d-inline-block" title="acu">
                                <FaCertificate size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-center">
                                <a href="https://www.coursera.org/account/accomplishments/verify/B8C9HVAGLN6D" target="_blank" rel="noopener noreferrer">Coursera - Computational Neuroscience</a>
                            </h4>
                        </div>
                        <div>
                            <span className="text-success d-inline-block" title="acu">
                                <FaCertificate size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-center">Springboard (E-Learning) - Foundations of Data Science</h4>
                        </div>
                        <div>
                            <span className="text-success d-inline-block" title="acu">
                                <FaCertificate size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-center">Google - The Online Marketing Fundamentals</h4>
                        </div>
                        <div>
                            <span className="text-success d-inline-block" title="acu">
                                <FaCertificate size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-center">
                                <a href="https://www.coursera.org/account/accomplishments/verify/2WKEY2SHV42C" target="_blank" rel="noopener noreferrer">Coursera - Machine Learning</a>
                            </h4>
                        </div>
                        <div>
                            <span className="text-success d-inline-block" title="acu">
                                <FaCertificate size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-center">
                                <a href="https://courses.cognitiveclass.ai/certificates/180257eb006b44cabd7b535a89a0b8ad" target="_blank" rel="noopener noreferrer">Big Data University - Machine Learning with Python</a>
                            </h4>
                        </div>
                        <div>
                            <span className="text-success d-inline-block" title="acu">
                                <FaCertificate size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-center">Wes Bos Courses - Learn Node — Master Package</h4>
                        </div>
                        <div>
                            <span className="text-success d-inline-block" title="acu">
                                <FaCertificate size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-center">MongoDB University</h4>
                            <p className="d-inline-block ml-3 w-75 align-center">
                                <a href="https://university.mongodb.com/course_completion/71aba006-a070-4dc9-b227-c1d9ea75/printable" target="_blank" rel="noopener noreferrer">M001: MongoDB Basics</a><br />
                                <a href="https://university.mongodb.com/course_completion/0905cf44-c5d3-4f57-8bc7-154ef5ae/printable" target="_blank" rel="noopener noreferrer">M201: MongoDB Performance</a><br />
                                <a href="https://university.mongodb.com/course_completion/6b955756-5e97-4cd4-9a7f-b8dd29cf/printable" target="_blank" rel="noopener noreferrer">M121: The MongoDB Aggregation Framework</a><br />
                                <a href="https://university.mongodb.com/course_completion/55c49b5b-edc1-42e7-80ee-5f323b90/printable" target="_blank" rel="noopener noreferrer">M101JS: MongoDB for Node.js Developers</a>
                            </p>
                        </div>
                        <div>
                            <span className="text-success d-inline-block" title="acu">
                                <FaCertificate size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-center">
                                <a href="https://www.udemy.com/certificate/UC-79HSVWSD/" target="_blank" rel="noopener noreferrer">Udemy - Full stack Universal React with Redux, Node js and MongoDB</a>
                            </h4>
                        </div>
                        <div>
                            <span className="text-success d-inline-block" title="acu">
                                <FaCertificate size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-center">DataCamp - Data Visualization with ggplot2 (Part 2)</h4>
                        </div>
                        <div>
                            <span className="text-success d-inline-block" title="acu">
                                <FaCertificate size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-center">DataCamp - Data Visualization with ggplot2 (Part 1)</h4>
                        </div>
                        <div>
                            <span className="text-success d-inline-block" title="acu">
                                <FaCertificate size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-center">DataCamp - Intermediate R</h4>
                        </div>
                        <div>
                            <span className="text-success d-inline-block" title="mobile">
                                <FaCertificate size={26} style={{ color: "#ff6e7e" }} />
                            </span>
                            <h4 className="d-inline-block ml-3 w-75 align-center">DataCamp - Introduction to R</h4>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
    query aboutQuery {
                    site {
                    siteMetadata {
                    labels {
                    tag
                    tech 
                name 
                size 
                color
            }
        }
    }
}
`

export default AboutPage

